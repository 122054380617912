import styled from 'styled-components';
import { ChatTitle } from 'src/core/components/ChatTitle';

export interface ContainerStyledProps {
  $active: boolean;
}

export const ContainerStyled = styled.div<ContainerStyledProps>`
  & {
    cursor: pointer;
    text-align: center;
    width: auto;
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ $active, theme }) => $active ? theme.palette.blue.h80 : 'transparent'};
  }
  &:hover {
    & p {
      color: ${({ theme }) => theme.palette.blue.h60} !important;
    }
    border-bottom: 1px solid ${({ theme }) => theme.palette.blue.h60};
  }
`;

export interface TitleStyledProps {
  $active: boolean;
}

export const TitleStyled = styled(ChatTitle) <TitleStyledProps>`
  & {
    user-select: none;
    -ms-user-select: none; 
    -moz-user-select: none; 
    -khtml-user-select: none; 
    -webkit-user-select: none; 
    -webkit-touch-callout: none; 
    color: ${({ $active, theme }) => $active ? theme.palette.blue.h80 : theme.palette.gray.h60};
  }
`;
