import React, { ReactElement } from 'react';

import { CompoundComponent } from 'src/core/types';
import { ChatMessageBody } from './ChatMessageBody';
import { ChatMessageHeader } from './ChatMessageHeader';
import { ChatMessageOptions } from './ChatMessageOptions';
import { ChatMessageContainer } from './ChatMessageContainer';
import { Container } from './styles';

type ChatBodyType = typeof ChatMessageBody;
type ChatHeaderType = typeof ChatMessageHeader;
type ChatOptionsType = typeof ChatMessageOptions;
type ChatContainerType = typeof ChatMessageContainer;

type ChatMessageExpectedChildren = ReactElement<ChatOptionsType> | ReactElement<ChatContainerType>;

export interface ChatMessageCompound {
  Body: ChatBodyType;
  Header: ChatHeaderType;
  Options: ChatOptionsType;
  Container: ChatContainerType;
}

export interface ChatMessageProps {
  children: ChatMessageExpectedChildren | ChatMessageExpectedChildren[];
}

export const ChatMessage: CompoundComponent<ChatMessageProps, ChatMessageCompound> = ({ children }) => {
  return (
    <Container>
      {children}
    </Container>
  );
}

ChatMessage.Body = ChatMessageBody;
ChatMessage.Header = ChatMessageHeader;
ChatMessage.Options = ChatMessageOptions;
ChatMessage.Container = ChatMessageContainer;

export * from './ChatMessageBody';
export * from './ChatMessageHeader';
export * from './ChatMessageOptions';
export * from './ChatMessageContainer';
