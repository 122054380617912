import React, { FunctionComponent, useMemo } from 'react';
import { UseMenuType } from 'src/core/hooks';
import { ContainerStyled, TitleStyled } from './styles';

export interface ChatTabItemProps extends React.HTMLAttributes<HTMLDivElement> {
  data?: any;
  menu: UseMenuType<any>; 
  children: string;
}

export const ChatTabItem: FunctionComponent<ChatTabItemProps> = ({ 
  data,
  menu,
  children,
  ...props
}) => {
  const [choiceId,,, setChoice, getId] = menu;
  const itemId = useMemo(() => getId(), [getId]);
  const isActive = itemId === choiceId;

  return (
    <ContainerStyled 
      $active={isActive} 
      onClick={() => setChoice(itemId, data)}
      {...props}
    >
      <TitleStyled $active={isActive}>
        {children}
      </TitleStyled>
    </ContainerStyled>
  );
}
