import styled from 'styled-components';

export const FlexRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const FlexAvatarColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 64px;
  flex-wrap: wrap;
`;

export const FlexUserColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
`;

export const FlexUserNameRowStyled = styled.div`
  display: flex;
  flex: 1;
  font-size: 17px;
`;

export const FlexDateRowStyled = styled.div`
  display: flex;
  flex: 1;
`;
