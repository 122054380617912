import styled from 'styled-components';

export const FlexRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  padding-top: 32px;
  font-size: 21px;
`;

export const MessageParagraphStyled = styled.p`
  white-space: pre-wrap;
  word-wrap: break-word; 
`;
