import dayjs from 'dayjs';
import { Avatar } from 'rsuite';
import React, { FunctionComponent, useMemo } from 'react';
import { 
  FlexAvatarColumnStyled, 
  FlexUserColumnStyled, 
  FlexRowStyled, 
  FlexUserNameRowStyled, 
  FlexDateRowStyled 
} from './styles';

export interface ChatMessageHeaderProps {
  avatar: string;
  userName: string;
  date: Date;
}

export const ChatMessageHeader: FunctionComponent<ChatMessageHeaderProps> = ({
  avatar,
  userName,
  date
}) => {
  const formattedDate = useMemo(() => {
    return dayjs(date).format('DD/MM/YYYY [às] HH:mm');
  }, [date]);

  return (
    <FlexRowStyled>
      <FlexAvatarColumnStyled>
        <Avatar 
          circle 
          src={avatar}
          alt={userName}
        />
      </FlexAvatarColumnStyled>
      <FlexUserColumnStyled>
        <FlexUserNameRowStyled>
          {userName}
        </FlexUserNameRowStyled>
        <FlexDateRowStyled>
          {formattedDate}
        </FlexDateRowStyled>
      </FlexUserColumnStyled>
    </FlexRowStyled>
  );
}
