import { useEffect } from 'react';
import { usePusher } from 'src/core/hooks/pusher';

export type OnBeforeChannelSubscribeListener = (channelUuid: string) => void;
export type OnChannelSubscribedListener = (channelUuid: string) => void;
export type OnChannelMessageCreatedListener = (channelUuid: string) => void;
export type OnMessageCreatedListener = (channelUuid: string) => void;
export type OnChannelUnbindListener = (channelUuid: string) => void;
export type OnChannelUnsubscribedListener = (channelUuid: string) => void;

export type UseChannelLifecycleType = void;

export interface UseChannelLifecycleOptions {
  channelUuid: string;
  onBeforeChannelSubscribe?: OnBeforeChannelSubscribeListener;
  onChannelSubscribed?: OnChannelSubscribedListener;
  onChannelMessageCreated?: OnChannelMessageCreatedListener;
  onMessageCreated?: OnMessageCreatedListener;
  onChannelUnbind?: OnChannelUnbindListener;
  onChannelUnsubscribed?: OnChannelUnsubscribedListener;
}

export function useChannelLifecycle(options: UseChannelLifecycleOptions): UseChannelLifecycleType {
  const pusher = usePusher();

  const {
    channelUuid,
    onBeforeChannelSubscribe,
    onChannelSubscribed,
    onMessageCreated,
    onChannelUnbind,
    onChannelUnsubscribed
  } = options;

  useEffect(() => {
    const channelKey = `channel_${channelUuid}`;
    onBeforeChannelSubscribe?.(channelUuid);
    const channel = pusher.subscribe(channelKey);

    if (channel.subscribed) {
      onChannelSubscribed?.(channelUuid);
    }

    channel.bind('message_created', () => {
      onMessageCreated?.(channelUuid);
    });

    return () => {
      pusher.unbind('message_created', () => {
        onChannelUnbind?.(channelUuid);
      }).unsubscribe(channelKey);

      if (!channel.subscribed) {
        onChannelUnsubscribed?.(channelUuid);
      }
    }
  }, [
    pusher,
    channelUuid,
    onBeforeChannelSubscribe,
    onMessageCreated,
    onChannelUnbind,
    onChannelSubscribed,
    onChannelUnsubscribed
  ]);
}
