import styled from 'styled-components';

export const Container = styled.div`
  & > .rs-input-group {

    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.blue.h60}!important;
    }
    
    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.blue.h40}!important;
    }
    
    & .rs-input {
      &:focus-visible {
        outline: 3px solid ${({ theme }) => theme.palette.blue.h20}!important;
      }
    }

  }
`;
