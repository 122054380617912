import React, { FunctionComponent } from 'react';
import { Render, useBearer } from 'src/core';
import { BoardPage } from 'src/pages';

export interface BrowserRoutesProps {}

export const BrowserRoutes: FunctionComponent<BrowserRoutesProps> = () => {
  const [isTokenValid] = useBearer();
  
  return (
    <Render if={isTokenValid}>
      <BoardPage />
    </Render>
  );
};
