import React, { FunctionComponent } from 'react';
import { InputGroup, InputGroupProps, Input, InputProps } from 'rsuite';
import { Render } from 'src/core/components/Render';
import { Container } from './styles';

export interface ChatInputProps extends InputGroupProps {
  inputProps?: InputProps;
  hasIcon?: boolean;
  icon?: React.ReactNode;
}

export const ChatInput: FunctionComponent<ChatInputProps> = ({ 
  inputProps, 
  hasIcon, 
  icon, 
  ...props 
}) => {
  return (
    <Container>
      <InputGroup {...props} inside>

        <Render if={Boolean(hasIcon)}>
          <InputGroup.Addon>
            {icon}
          </InputGroup.Addon>
        </Render>

        <Input {...inputProps} />
      </InputGroup>
    </Container>
  );
}
