import { useCallback, useLayoutEffect, useRef, useState } from 'react';

export type MessageEventPayload<T> = { 
  type: string;
  data: T
};

export type MessageListener<T = any> = (payload: MessageEventPayload<T>) => void;

export interface UseMessageListenerOptions {
  origins?: string[];
  type?: string
  isJson?: boolean;
}

export type UseMessageListenerType<T> = [
  MessageEventPayload<T> | undefined,
  UseMessageListenerOptions | undefined
];

export const USE_MESSAGE_LISTENER_DEFAULT_OPTIONS: UseMessageListenerOptions = {};

export function useMessageListener<T>(
  options?: UseMessageListenerOptions,
  listener?: MessageListener<T>
): UseMessageListenerType<T> 
{
  const listenerRefs = useRef<((event: MessageEvent) => void)[]>([]);
  const [lastPayload, setLastPayload] = useState<MessageEventPayload<T> | undefined>(undefined);

  const messageListener = useCallback((event: MessageEvent) => {
    try {
      const {
        origins,
        type,
        isJson
      } = options ?? USE_MESSAGE_LISTENER_DEFAULT_OPTIONS;

      //This will discard messages sent by extraneous origins.
      //Should be considered due to security purposes.
      if (origins && !origins.includes(event.origin)) {
        return;
      }

      const payload: MessageEventPayload<T> = isJson
        ? JSON.parse(event.data)
        : event.data;

      //This will discard messages that are malformed or don't have the correct type.
      //All messages SHOULD have the same format, described by MessageEventPayload type.
      if (typeof payload.type === 'undefined' || (type && payload.type !== type)) {
        return;
      }

      listener?.(payload);
      setLastPayload(payload);
    } catch (error) {
      return;
    }
  }, [options, listener]);

  useLayoutEffect(() => {
    listenerRefs.current.forEach((listener) => {
      window.removeEventListener('message', listener);
    });

    listenerRefs.current = [];
    window.addEventListener('message', messageListener);
    listenerRefs.current.push(messageListener);
  }, [messageListener]);

  return [lastPayload, options];
}
