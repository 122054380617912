import styled from 'styled-components';

export const BorderStyled = styled.div`
  width: 6px;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.green.h60};
  border-radius: 3px;
`;

export interface ContainerStyledProps {
  $active: boolean;
}

export const ContainerStyled = styled.div<ContainerStyledProps>`
  & {
    display: flex;
    align-items: center;
    height: 79px;
    border: 1px solid ${({ $active, theme }) => $active 
      ? 'transparent' 
      : theme.palette.gray.h20};
    border-radius: 3px;
    background-color: ${({ $active, theme }) => $active 
      ? theme.palette.gray.h10 
      : theme.palette.white.h100};
    cursor: pointer;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.gray.h10};
  }
`;

export interface NameStyledProps {
  $active: boolean;
}

export const NameStyled = styled.p<NameStyledProps>`
  & {
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: ${({ $active }) => $active ? '22px' : '28px'};
    width: 100%;
    color: ${({ theme }) => theme.palette.gray.h80};
  }
`;
