import { StackProps } from 'rsuite';
import React, { FunctionComponent } from 'react';
import { StackStyled } from './styles';

export interface FlexColumnProps extends Omit<StackProps, 'direction'> {
  direction?: 'column' | 'column-reverse';
  fullWidth?: boolean;
  fullHeight?: boolean;
  children: React.ReactNode;
}

export const FlexColumn: FunctionComponent<FlexColumnProps> = ({ 
  direction = 'column', 
  fullWidth = false,
  fullHeight = false,
  children,
  ...props
}) => {
  return (
    <StackStyled 
      direction={direction} 
      $fullWidth={fullWidth}
      $fullHeight={fullHeight}
      {...props}
    >
      {children}
    </StackStyled>
  );
}
