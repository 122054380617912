import React, { FunctionComponent } from 'react';
import { TitleStyled } from './styles';

export interface ChatTitleProps extends React.HTMLAttributes<HTMLParagraphElement> {
  children: string;
}

export const ChatTitle: FunctionComponent<ChatTitleProps> = ({ children, ...props }) => {
  return (
    <TitleStyled {...props}>
      {children}
    </TitleStyled>
  );
}
