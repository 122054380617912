import React, { ReactElement } from 'react';
import { Stack } from 'rsuite';
import { CompoundComponent } from 'src/core/types';
import { ChatTabItem } from './ChatTabItem';

type ChatTabMenuExpectedChildren = ReactElement<typeof ChatTabItem>;

export interface ChatTabMenuProps {
  children: ChatTabMenuExpectedChildren | ChatTabMenuExpectedChildren[];
}

export interface ChatTabMenuCompound {
  Item: typeof ChatTabItem;
}

export const ChatTabMenu: CompoundComponent<ChatTabMenuProps, ChatTabMenuCompound> = ({ children }) => {
  return (
    <Stack spacing={40}>
      {children}
    </Stack>
  );
}

ChatTabMenu.Item = ChatTabItem;

export * from './ChatTabItem';
