import Pusher from 'pusher-js';
import { useContext } from 'react'
import { PusherContext } from 'src/core/providers'

export function usePusher(): Pusher {
  const pusher = useContext(PusherContext);
  if (!pusher) {
    throw new Error('usePusher must be used within a PusherProvider');
  }
  const [pusherInstance] = pusher;
  return pusherInstance;
}
