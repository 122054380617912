import React, { FunctionComponent, useMemo } from 'react';
import { Render } from 'src/core/components/Render';
import { UseMenuType } from 'src/core/hooks';
import { BorderStyled, ContainerStyled, NameStyled } from './styles';

export interface ChatChannelItemProps extends React.HTMLAttributes<HTMLDivElement> {
  data?: any;
  menu: UseMenuType<any>; 
  children: string;
}

export const ChatChannelItem: FunctionComponent<ChatChannelItemProps> = ({ 
  data,
  menu,
  children
}) => {
  const [choiceId,,, setChoice, getId] = menu;
  const itemId = useMemo(() => getId(), [getId]);
  const isActive = itemId === choiceId;

  return (
    <ContainerStyled 
      $active={isActive}
      onClick={() => setChoice(itemId, data)}
    >
      <Render if={isActive}>
        <BorderStyled />
      </Render>
      <NameStyled $active={isActive}>
        {children}
      </NameStyled>
    </ContainerStyled>
  );
}
