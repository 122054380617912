import React from 'react';
import { CompoundComponent } from 'src/core/types';
import { LayoutHeader } from './LayoutHeader';
import { LayoutFooter } from './LayoutFooter';
import { LayoutContent } from './LayoutContent';
import { LayoutSidebar } from './LayoutSidebar';
import { ContainerStyled } from './styles';

export interface LayoutProps {
  children: React.ReactNode;
}

export interface LayoutCompound {
  Header: typeof LayoutHeader;
  Footer: typeof LayoutFooter;
  Content: typeof LayoutContent;
  Sidebar: typeof LayoutSidebar;
}

export const Layout: CompoundComponent<LayoutProps, LayoutCompound> = ({ children }) => {
  return (
    <ContainerStyled>
      {children}
    </ContainerStyled>
  );
}

Layout.Header = LayoutHeader;
Layout.Content = LayoutContent;
Layout.Sidebar = LayoutSidebar;
Layout.Footer = LayoutFooter;

export * from './LayoutHeader';
export * from './LayoutFooter';
export * from './LayoutSidebar';
export * from './LayoutContent';
