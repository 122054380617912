import React, { ReactElement } from 'react';
import { Flex } from 'src/core/components/Flex';
import { CompoundComponent } from 'src/core/types';
import { ChatChannelItem } from './ChatChannelItem';

export interface ChatChannelMenuProps {
  children: ReactElement<typeof ChatChannelItem>[];
}

export interface ChatChannelMenuCompound {
  Item: typeof ChatChannelItem;
}

export const ChatChannelMenu: CompoundComponent<ChatChannelMenuProps, ChatChannelMenuCompound> = ({ children }) => {
  return (
    <Flex>
      <Flex.Column fullWidth>
        {children}
      </Flex.Column>
    </Flex>
  );
}

ChatChannelMenu.Item = ChatChannelItem;

export * from './ChatChannelItem';
