import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { FetchScheduleChannelsResponse } from 'src/core/types';
import { useAxios } from './useAxios';

//Retorna os canais disponíveis para o usuário
export function useChannels(): UseQueryResult<FetchScheduleChannelsResponse> {
  const [axios] = useAxios();

  const getChannels = useCallback(async () => {
    const { data } = await axios.get<FetchScheduleChannelsResponse>('/api/v1/schedules/channels');

    return data;
  }, [axios]);

  return useQuery('getChannels', () => getChannels());
}
