import { ButtonProps } from 'rsuite';
import React, { FunctionComponent } from 'react';
import { ButtonStyled } from './styles';

export interface ChatButtonProps extends ButtonProps {
  isBold: boolean;
  isUppercase: boolean;
  customColor?: string;
  customColorOnHover?: string;
  customColorOnFocus?: string;
  children: React.ReactNode;
}

export const ChatButton: FunctionComponent<ChatButtonProps> = ({ 
  children,
  isBold, 
  isUppercase,
  customColor,
  customColorOnHover,
  customColorOnFocus,
  ...props 
}) => {
  return (
    <ButtonStyled 
      $isBold={isBold}
      $isUppercase={isUppercase}
      $customColor={customColor}
      $customColorOnHover={customColorOnHover}
      $customColorOnFocus={customColorOnFocus}
      {...props}
    >
      {children}
    </ButtonStyled>
  );
}
