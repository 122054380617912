import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { DeleteMessageResponse } from 'src/core/types';
import { useAxios } from './useAxios';

export function useDeleteMessage() {
  const [axios] = useAxios();

  const deleteMessage = useCallback(async (messageUuid: string) => {
    return await axios.delete<string, DeleteMessageResponse>(
      `/api/v1/messages/${messageUuid}`
    );
  }, [axios]);

  return useMutation('useDeleteMessage', 
    (messageUuid: string) => deleteMessage(messageUuid));
}
