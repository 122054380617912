import React, { FunctionComponent } from 'react';
import { SidebarProps } from 'rsuite';
import { SidebarStyled } from './styles';

export interface LayoutSidebarProps extends SidebarProps {
  children: React.ReactNode;
}

export const LayoutSidebar: FunctionComponent<LayoutSidebarProps> = ({ children, ...props }) => {
  return (
    <SidebarStyled {...props}>
      {children}
    </SidebarStyled>
  );
}
