import React, { FunctionComponent, PropsWithChildren } from 'react';
import { AxiosProvider } from './AxiosProvider';
import { ThemeProvider } from './ThemeProvider';
import { PusherProvider } from './PusherProvider';
import { BearerProvider } from './BearerProvider';
import { MixpanelProvider } from './MixpanelProvider';
import { ReactSuiteProvider } from './ReactSuiteProvider';
import { ReactQueryProvider } from './ReactQueryProvider';

export const CoreProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <BearerProvider>
      <AxiosProvider>
        <ReactQueryProvider>
          <ReactSuiteProvider>
            <PusherProvider>
              <MixpanelProvider>
                <ThemeProvider>
                  {children}
                </ThemeProvider>
              </MixpanelProvider>
            </PusherProvider>
          </ReactSuiteProvider>
        </ReactQueryProvider>
      </AxiosProvider>
    </BearerProvider>
  );
}
