import React from 'react';
import { CheckPickerProps } from 'rsuite';
import { SelectStyled } from './styles';

export interface ChatSelectProps<V> extends CheckPickerProps<V> { }

export function ChatSelect<V>({ ...props }: ChatSelectProps<V>) {
  return (
    <SelectStyled {...props} />
  );
}
