import { useContext } from 'react';
import { BearerContext } from 'src/core/providers';

export type UseBearerType = [boolean, string];

export function useBearer(): UseBearerType {
  const state = useContext(BearerContext);
  if (!state) {
    throw new Error('useBearer must be used within an BearerProvider');
  }
  return state;
}
