import Pusher from 'pusher-js';
import React, { createContext, FunctionComponent, useState } from 'react';

const PusherDefaultInstance = new Pusher(process.env.REACT_APP_PUSHER_CLIENT_KEY || '', {
  forceTLS: true,
  cluster: 'mt1',
});

export type PusherSetter = (pusherInstance: Pusher) => void;
export type PusherProviderValue = [Pusher, PusherSetter];

export interface PusherProviderProps {
  children: React.ReactNode;
}

export const PusherContext = createContext<PusherProviderValue>([PusherDefaultInstance, () => {}]);

export const PusherProvider: FunctionComponent<PusherProviderProps> = ({ children }) => {
  const state = useState<Pusher>(PusherDefaultInstance);
  return (
    <PusherContext.Provider value={state}>
      {children}
    </PusherContext.Provider>
  );
}
