import React, { FunctionComponent } from 'react';
import { HeaderProps } from 'rsuite';
import { HeaderStyled } from './styles';

export interface LayoutHeaderProps extends HeaderProps {
  children: React.ReactNode;
}

export const LayoutHeader: FunctionComponent<LayoutHeaderProps> = ({ children, ...props }) => {
  return (
    <HeaderStyled {...props}>
      {children}
    </HeaderStyled>
  );
}
