import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 54px;
  padding-right: 54px;
  border-bottom: 1px solid #DBDBDB;
`;
