import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { FetchSchedules } from 'src/core/types';
import { useAxios } from './useAxios';

//Retorna os canais disponíveis para o usuário
export function useSchedules(): UseQueryResult<FetchSchedules, Error> {
  const [axios] = useAxios();

  const getSchedules = useCallback(async () => {
    const { data } = await axios.get<FetchSchedules>('/api/v1/chat/schedules');

    return data;
  }, [axios]);

  return useQuery('getSchedules', () => getSchedules());
}
