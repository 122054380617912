import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { PublishScheduleMessage, PublishScheduleMessageResponse } from 'src/core/types';
import { useAxios } from './useAxios';

export function useCreateScheduleMessage(options = {}) {
  const [axios] = useAxios();

  const createScheduleMessage = useCallback(async (data: PublishScheduleMessage) => {
    return await axios.post<
      PublishScheduleMessage, 
      PublishScheduleMessageResponse
    >('/api/v1/schedules/messages', data);
  }, [axios]);

  return useMutation('createScheduleMessage', 
    (data: PublishScheduleMessage) => createScheduleMessage(data), 
    options
  );
}
