import React, { FunctionComponent } from 'react';
import { FlexRowStyled, MessageParagraphStyled } from './styles';

export interface ChatMessageBodyProps {
  children: string;
}

export const ChatMessageBody: FunctionComponent<ChatMessageBodyProps> = ({ children }) => {
  return (
    <FlexRowStyled>
      <MessageParagraphStyled>
        {children}
      </MessageParagraphStyled>
    </FlexRowStyled>
  );
}
