import React, { FunctionComponent } from 'react';
import { IoEyeOutline, IoTrashOutline } from 'react-icons/io5';
import { Render } from 'src/core/components/Render';
import { FlexColumnStyled } from './styles';

export interface ChatMessageOptionsProps {
  id: string;
  onDeleteMessage?: (id: string) => void;
  onSeeMessage?: (id: string) => void;
  canSeeMessage?: boolean;
  canDeleteMessage?: boolean;
  children?: React.ReactNode;
}

export const ChatMessageOptions: FunctionComponent<ChatMessageOptionsProps> = ({ 
  id,
  onDeleteMessage,
  onSeeMessage,
  canSeeMessage,
  canDeleteMessage,
  children
}) => {
  return (
    <FlexColumnStyled>
      <Render if={Boolean(canSeeMessage)}>
        <IoEyeOutline onClick={() => onSeeMessage?.(id)} />
      </Render>
      <Render if={Boolean(canDeleteMessage)}>
        <IoTrashOutline onClick={() => onDeleteMessage?.(id)} />
      </Render>
      {children}
    </FlexColumnStyled>
  );
}
