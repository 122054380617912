import styled from 'styled-components';

export const Container = styled.div`
  & > textarea[class*="rs-input"] {
    
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.blue.h60}!important;
    }

    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.blue.h40}!important;
    }
  
    &:focus-visible {
      outline: 3px solid ${({ theme }) => theme.palette.blue.h20}!important;
    }

  }
`;
