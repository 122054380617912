import { Stack } from 'rsuite';
import styled from 'styled-components';

export interface StackStyledProps {
  $fullWidth: boolean;
  $fullHeight: boolean;
}

export const StackStyled = styled(Stack)<StackStyledProps>`
  & {
    height: ${({ $fullHeight }) => $fullHeight ? `100%` : `auto`};
    width: ${({ $fullWidth }) => $fullWidth ? `100%` : `auto`};
  }
  & > .rs-stack-item {
    height: ${({ $fullHeight }) => $fullHeight ? `100%` : `auto`};
    width: ${({ $fullWidth }) => $fullWidth ? `100%` : `auto`};
  }
`;
