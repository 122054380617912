import styled from 'styled-components';
import { Button } from 'rsuite';

export interface ButtonStyledProps {
  $isUppercase: boolean;
  $isBold: boolean;
  $customColor?: string;
  $customColorOnHover?: string;
  $customColorOnFocus?: string;
}

export const ButtonStyled = styled(Button)<ButtonStyledProps>`
  & {
    padding-left: 24px;
    padding-right: 24px;
    color: ${({ theme }) => theme.palette.white.h100};
    background-color: ${({ $customColor, theme }) => $customColor || theme.palette.blue.h80} ;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: ${({ $isBold }) => $isBold ? 'bold' : 'inherit'};
    text-transform: ${({ $isUppercase }) => $isUppercase ? 'uppercase' : 'none'};
  }

  &:hover {
    background-color: ${({ $customColorOnHover, theme }) => $customColorOnHover || theme.palette.blue.h90};
    color: ${({ theme }) => theme.palette.white.h100};
  }

  &:focus {
    background-color: ${({ $customColorOnFocus, theme }) => $customColorOnFocus || theme.palette.blue.h60};
    color: ${({ theme }) => theme.palette.white.h100};
  }
`;
