import { useCallback } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { FetchScheduleChannelMessagesResponse } from 'src/core/types';
import { useAxios } from './useAxios';

// Retorna as mensagens de um uma escala pelo id
export function useChannelMessages(scheduleId: number|null|undefined, afterTimestamp: string = '', options={}): UseQueryResult<FetchScheduleChannelMessagesResponse> {
  const [axios] = useAxios();

  const getChannelMessages = useCallback(async () => {
    if (!scheduleId) {
      return [];
    }

    const { data } = await axios.get<FetchScheduleChannelMessagesResponse>(
      `/api/v1/schedules/${scheduleId}/messages`, 
      {
        params: {
          after_timestamp: afterTimestamp,
        }
      }
    );

    return data;
  }, [
    axios, 
    scheduleId, 
    afterTimestamp
  ]);

  const getChannelMessagesCallback = useCallback(() => getChannelMessages(), [getChannelMessages]);

  return useQuery('getChannelMessages', getChannelMessagesCallback, options);
}
