import { Divider } from 'rsuite';
import { v4 as uuidv4 } from 'uuid';
import { Search } from '@rsuite/icons';

import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { 
  FLAG_ENABLE_INCLUDE_ATTACHMENTS, 
  FLAG_ENABLE_IS_READ_RECEIPT_CHECKBOX 
} from 'src/core/flags';

import { 
  ScheduleChannel, 
  ScheduleChannelMessage 
} from 'src/core/types';

import {
  ChatButton,
  ChatChannelMenu,
  ChatCheckbox,
  ChatInput,
  ChatMessage,
  ChatTabMenu,
  ChatTitle,
  Flex,
  Layout,
  Render,
  useCreateScheduleMessage,
  useMenu,
  useChannels,
  useChannelMessages,
  useDeleteMessage,
  useChannelLifecycle,
  useSchedules,
  useTrack,
  MixpanelEvent
} from 'src/core';

import { 
  MessageCreationModal, 
  MessageCreationModalResult 
} from 'src/pages/Board/components';

import {
  ChatTextAreaStyled,
  FlexColumnMessagesStyled,
  FlexColumnMessageUserControlsStyled,
  FlexHeaderColumnStyled,
  FlexSidebarColumnStyled,
  SidebarRightPaddingStyled,
  VerticalSpacerStyled
} from './styles';

const EMPTY_SCHEDULES: ScheduleChannel[] = [];
const EMPTY_MESSAGES: ScheduleChannelMessage[] = [];

export interface BoardPageProps { }

export const BoardPage: FunctionComponent<BoardPageProps> = () => {
  const [message, setMessage] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isReadReceiptEnabled, setIsReadReceiptEnabled] = useState<boolean>(true);
  
  const track = useTrack();
  const tabMenu = useMenu<string>();
  const channelMenu = useMenu<ScheduleChannel>();

  const [, channelData, , setChannelMenuChoice] = channelMenu;
  const selectedScheduleId = channelData?.schedule_id;
  const selectedChannelUuid = channelData?.chat_channel_uuid;

  const [deletedMessagesByKey, setDeletedMessagesByKey] = useState<Record<string, boolean>>({});
  const [temporaryMessages, setTemporaryMessages] = useState<ScheduleChannelMessage[]>([]);

  const {
    mutate: createScheduleMessage
  } = useCreateScheduleMessage({
    onSuccess: () => {
      getChannelMessages();
    }
  });

  const {
    data: channelsResponse,
    refetch: getChannels
  } = useChannels();

  const {
    data: schedules,
    refetch: getSchedules
  } = useSchedules();

  const { mutate: deleteMessage } = useDeleteMessage();

  const scheduleChannels: ScheduleChannel[] = channelsResponse?.data || EMPTY_SCHEDULES;

  const {
    data: channelMessagesResponse,
    refetch: getChannelMessages,
    isLoading: isLoadingMessages
  } = useChannelMessages(selectedScheduleId, undefined, {
    onSuccess: () => {
      setTemporaryMessages([]);
      setDeletedMessagesByKey({});
    }
  });

  const messages: ScheduleChannelMessage[] = (!isLoadingMessages && channelMessagesResponse?.data) || EMPTY_MESSAGES;

  const messagesToShow = useMemo(() => {
    return [...messages.filter(m => !deletedMessagesByKey[m.message_uuid]), ...temporaryMessages];
  }, [messages, deletedMessagesByKey, temporaryMessages])

  useEffect(() => {
    if (selectedScheduleId || scheduleChannels.length === 0) {
      return;
    }

    setChannelMenuChoice(0, scheduleChannels[0]);
  }, [
    channelMenu,
    setChannelMenuChoice,
    scheduleChannels,
    selectedScheduleId
  ]);

  useEffect(() => {
    getChannels();
    getSchedules();
  }, [getChannels, getSchedules]);

  const onChangeMessage = useCallback((value: string) => setMessage(value), []);
  const onClickOpenModal = useCallback(() => setIsModalOpen(true), []);
  const onCloseModal = useCallback(() => setIsModalOpen(false), []);

  const onModalSubmit = useCallback((result: MessageCreationModalResult) => {
    createScheduleMessage({
      schedule_ids: result.selected.map(schedule => schedule.id),
      message: result.message,
      is_read_receipt_enabled: result.isReadReceiptEnabled
    }, {
      onSuccess: () => getChannels()
    });
    
    track(MixpanelEvent.MessageCreated);
    setIsModalOpen(false);
  }, [
    createScheduleMessage, 
    track, 
    getChannels
  ]);

  const onSendMessage = useCallback(() => {
    if (selectedScheduleId && message.length > 0) {
      setTemporaryMessages(messages => [...messages, {
        message_uuid: uuidv4(),
        message: message,
        is_read_receipt_enabled: isReadReceiptEnabled,
        timestamp: new Date().toString(),
        user: {
          user_uuid: uuidv4(),
          name: 'Processando...',
          photo: '',
          email: '',
        }
      }]);

      createScheduleMessage({
        schedule_ids: [selectedScheduleId],
        message: message,
        is_read_receipt_enabled: isReadReceiptEnabled
      });
    }
    setMessage('');
  }, [
    createScheduleMessage,
    isReadReceiptEnabled,
    message,
    selectedScheduleId
  ]);

  const onChangeReadReceiptEnabled = useCallback((value: any, checked: boolean) => {
    setIsReadReceiptEnabled(checked);

    track(checked 
      ? MixpanelEvent.ReadReceiptEnabled 
      : MixpanelEvent.ReadReceiptDisabled 
    );
  }, [track]);

  const deleteMessageAndSendRequest = useCallback((uuid: string) => {
    setDeletedMessagesByKey(keys => ({ ...keys, [uuid]: true }))
    deleteMessage(uuid);

    track(MixpanelEvent.MessageDeleted);
  }, [deleteMessage, track]);

  const onGetChannelMessages = useCallback(() => getChannelMessages(), [getChannelMessages]);

  useChannelLifecycle({
    channelUuid: selectedChannelUuid ?? '',
    onBeforeChannelSubscribe: onGetChannelMessages,
    onMessageCreated: onGetChannelMessages,
  });

  return (
    <Layout>
      <Layout.Header>
        <Flex>
          <FlexHeaderColumnStyled justifyContent="center">
            <ChatTabMenu>
              <ChatTabMenu.Item menu={tabMenu} data="Mural de recados">
                Mural de recados
              </ChatTabMenu.Item>
            </ChatTabMenu>
          </FlexHeaderColumnStyled>
        </Flex>
      </Layout.Header>

      <Layout>
        <Layout.Sidebar>
          <Flex>
            <FlexSidebarColumnStyled fullWidth>

              <VerticalSpacerStyled size={40} />

              <SidebarRightPaddingStyled>
                <ChatTitle>
                  Conversas
                </ChatTitle>

                <VerticalSpacerStyled size={48} />

                <ChatButton
                  isBold
                  isUppercase
                  size="lg"
                  onClick={onClickOpenModal}
                >
                  Novo recado
                </ChatButton>

                <VerticalSpacerStyled size={40} />

                <ChatInput
                  hasIcon
                  icon={<Search />}
                  size="lg"
                  inputProps={{
                    placeholder: 'Busque uma escala'
                  }}
                />
              </SidebarRightPaddingStyled>

              <VerticalSpacerStyled size={58} />

              <ChatChannelMenu>
                {scheduleChannels.map((channel) => (
                  <ChatChannelMenu.Item
                    key={channel.chat_channel_uuid}
                    menu={channelMenu}
                    data={channel}
                  >
                    {channel.schedule_name}
                  </ChatChannelMenu.Item>
                ))}
              </ChatChannelMenu>

              <VerticalSpacerStyled size={48} />

            </FlexSidebarColumnStyled>
          </Flex>
        </Layout.Sidebar>

        <Layout.Content>
          <Flex fullWidth>

            <Flex.Column fullWidth>
              <FlexColumnMessagesStyled fullWidth>

                <Divider>
                  Não existem novos recados
                </Divider>

                {messagesToShow.map(messageData => (
                  <ChatMessage key={messageData.message_uuid}>
                    <ChatMessage.Container>
                      <ChatMessage.Header
                        avatar={messageData.user.photo}
                        userName={messageData.user.name}
                        date={new Date(messageData.timestamp + 'Z')}
                      />

                      <ChatMessage.Body>
                        {messageData.message}
                      </ChatMessage.Body>
                    </ChatMessage.Container>

                    <ChatMessage.Options
                      id={messageData.message_uuid}
                      canSeeMessage={false}
                      canDeleteMessage={true}
                      onDeleteMessage={deleteMessageAndSendRequest}
                    />

                  </ChatMessage>
                ))}
              </FlexColumnMessagesStyled>

              <Divider />

              <FlexColumnMessageUserControlsStyled fullWidth>
                <ChatTextAreaStyled
                  placeholder="Escreva um recado"
                  onChange={onChangeMessage}
                  value={message}
                />
                <VerticalSpacerStyled size={16} />

                <Flex.Row justifyContent="space-between">

                  <Render if={FLAG_ENABLE_INCLUDE_ATTACHMENTS}>
                    <ChatButton isBold isUppercase size="lg" disabled>
                      Incluir Anexo
                    </ChatButton>
                  </Render>

                  <ChatButton
                    isBold
                    isUppercase
                    onClick={onSendMessage}
                    size="lg"
                  >
                    Enviar
                  </ChatButton>
                </Flex.Row>
                <VerticalSpacerStyled size={8} />

                <Render if={FLAG_ENABLE_IS_READ_RECEIPT_CHECKBOX}>
                  <ChatCheckbox
                    checked={isReadReceiptEnabled}
                    onChange={onChangeReadReceiptEnabled}
                  >
                    Todos os leitores serão obrigados a marcar esta postagem como visualizada
                  </ChatCheckbox>
                  <VerticalSpacerStyled size={32} />
                </Render>
              </FlexColumnMessageUserControlsStyled>
            </Flex.Column>

          </Flex>

          <MessageCreationModal
            isOpen={isModalOpen}
            onClose={onCloseModal}
            onSubmit={onModalSubmit}
            data={schedules?.data ?? []}
          />
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
