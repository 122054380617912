import RSuite from 'rsuite';
import React, { FunctionComponent } from 'react';
import { CheckboxStyled } from './styles';

export interface ChatCheckboxProps extends RSuite.CheckboxProps {
  children: string;
}

export const ChatCheckbox: FunctionComponent<ChatCheckboxProps> = ({ children, ...props }) => {
  return (
    <CheckboxStyled {...props}>
      {children}
    </CheckboxStyled>
  );
}
