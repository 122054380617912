import styled from 'styled-components';

export const FlexColumnStyled = styled.div`
  & {
    display: flex;
    flex-direction: row;
    flex: 0 0 160px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    gap: 8px;
    font-size: 24px;
  }
  
  & > div, svg, a, button {
    cursor: pointer;
  }

  & > div:hover, 
    svg:hover, 
    a:hover, 
    button:hover 
  {
    color: ${({ theme }) => theme.palette.blue.h80};
  }
`;
