import { CustomProvider } from 'rsuite';
import React, { FunctionComponent } from 'react';

export interface ReactSuiteProviderProps {
  children: React.ReactNode;
}

export const ReactSuiteProvider: FunctionComponent<ReactSuiteProviderProps> = ({ children }) => {
  return (
    <CustomProvider>
      {children}
    </CustomProvider>
  );
}
