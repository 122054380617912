import React, { createContext, FunctionComponent, PropsWithChildren } from 'react';

import { BearerMessagePayload } from 'src/core/types';
import { useMessageListener, UseMessageListenerOptions } from 'src/core/hooks/platform';

export type BearerProviderValue = [boolean, string];

export const BearerContext = createContext<BearerProviderValue>([false, '']);

export const BearerMessageOptions: UseMessageListenerOptions = {
  type: 'bearer_token',
  isJson: true
};

export const BearerProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [payload] = useMessageListener<BearerMessagePayload>(BearerMessageOptions);

  const token = payload?.data?.token ?? '';
  const isTokenValid = !!payload?.data?.token;

  const state: BearerProviderValue = [isTokenValid, token];

  return (
    <BearerContext.Provider value={state}>
      {children}
    </BearerContext.Provider>
  );
}
