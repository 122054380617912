import React, { ReactElement } from 'react';
import { CompoundComponent } from 'src/core/types';
import { FlexColumn } from './FlexColumn';
import { FlexRow } from './FlexRow';
import { FlexStyled } from './styles';

type FlexColumnType = typeof FlexColumn;
type FlexRowType = typeof FlexRow;

type FlexExpectedChildren = ReactElement<FlexColumnType> | ReactElement<FlexRowType>;

export interface FlexCompound {
  Column: FlexColumnType;
  Row: FlexRowType;
}

export interface FlexProps {
  fullWidth?: boolean;
  fullHeight?: boolean;
  children: FlexExpectedChildren | FlexExpectedChildren[];
}

export const Flex: CompoundComponent<FlexProps, FlexCompound> = ({
  children,
  fullWidth = true,
  fullHeight = true,
}) => {
  return (
    <FlexStyled
      fullWidth={fullWidth}
      fullHeight={fullHeight}
    >
      {children}
    </FlexStyled>
  );
}

Flex.Column = FlexColumn;
Flex.Row = FlexRow;

export * from './FlexColumn';
export * from './FlexRow';
