import React, { Fragment, FunctionComponent } from 'react';

export interface RenderProps {
  children: React.ReactNode;
  if: boolean;
}

export const Render: FunctionComponent<RenderProps> = ({ children, if: condition }) => {
  return condition ? <Fragment>{children}</Fragment> : <Fragment />;
}
