import styled from 'styled-components';
import { ChatTextArea, Flex } from 'src/core';

export const FlexHeaderColumnStyled = styled(Flex.Column)`
  padding: 0 56px;
`;

export const FlexSidebarColumnStyled = styled(Flex.Column)`
  padding-left: 56px;
`;

export interface VerticalSpacerStyledProps {
  size?: number;
}

export const VerticalSpacerStyled = styled.div<VerticalSpacerStyledProps>`
  display: flex;
  flex: 1;
  height: ${({ size }) => size ? size : 8}px;
`;

export const SidebarRightPaddingStyled = styled.div`
  padding-right: 32px;
`;

export const ChatTextAreaStyled = styled(ChatTextArea)`
  width: 100%;
`;

export const FlexColumnMessagesStyled = styled(Flex.Column)`
  min-height: 640px;
  max-height: 960px;
  height: 640px;
  overflow-y: auto;
`;

export const FlexColumnMessageUserControlsStyled = styled(Flex.Column)`
  padding-left: 56px;
  padding-right: 56px;
`;
