import React, { FunctionComponent } from 'react';
import { 
  ThemeProvider as StyledThemeProvider 
} from 'styled-components';

import { RebrandingTheme } from 'src/core/themes';

export interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: FunctionComponent<ThemeProviderProps> = ({ children }) => {
  return (
    <StyledThemeProvider theme={RebrandingTheme}>
      {children}
    </StyledThemeProvider>
  );
}
