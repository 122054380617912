import { ChatSelect, ChatTextArea, ChatTitle } from 'src/core';
import styled from 'styled-components';

export const ChatTitleStyled = styled(ChatTitle)`
  padding-bottom: 8px;
`;

export const ChatSelectStyled = styled(ChatSelect)`
  width: 100%;
  max-width: 280px;
`;

export const ChatTextAreaStyled = styled(ChatTextArea)`
  width: 100%;
`;

export interface VerticalSpacerStyledProps {
  size?: number;
}

export const VerticalSpacerStyled = styled.div<VerticalSpacerStyledProps>`
  display: flex;
  flex: 1;
  height: ${({ size }) => size ? size : 8}px;
`;

export const SubmitContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
