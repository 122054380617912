import React, { FunctionComponent, ReactElement } from 'react';
import { ChatMessageBody } from 'src/core/components/ChatMessage/ChatMessageBody';
import { ChatMessageHeader } from 'src/core/components/ChatMessage/ChatMessageHeader';
import { FlexColumnStyled } from './styles';

type ChatBodyType = typeof ChatMessageBody;
type ChatHeaderType = typeof ChatMessageHeader;

type ChatMessageContainerExpectedChildren = ReactElement<ChatBodyType> | ReactElement<ChatHeaderType>;

export interface ChatMessageContainerProps {
  children: ChatMessageContainerExpectedChildren | ChatMessageContainerExpectedChildren[];
}

export const ChatMessageContainer: FunctionComponent<ChatMessageContainerProps> = ({ children }) => {
  return (
    <FlexColumnStyled>
      {children}
    </FlexColumnStyled>
  );
}
