import React, { FunctionComponent } from 'react';
import { FooterProps } from 'rsuite';
import { FooterStyled } from './styles';

export interface LayoutFooterProps extends FooterProps {
  children: React.ReactNode;
}

export const LayoutFooter: FunctionComponent<LayoutFooterProps> = ({ children, ...props }) => {
  return (
    <FooterStyled {...props}>
      {children}
    </FooterStyled>
  );
}
