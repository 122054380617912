import React, { FunctionComponent } from 'react';
import { ContentProps } from 'rsuite';
import { ContentStyled } from './styles';

export interface LayoutContentProps extends ContentProps {
  children: React.ReactNode;
}

export const LayoutContent: FunctionComponent<LayoutContentProps> = ({ children, ...props }) => {
  return (
    <ContentStyled {...props}>
      {children}
    </ContentStyled>
  );
}
