import styled from 'styled-components';

export interface FlexStyledProps {
  fullWidth: boolean;
  fullHeight: boolean;
}

export const FlexStyled = styled.div<FlexStyledProps>`
  display: flex;
  height: ${({ fullHeight }) => fullHeight ? `100%` : `auto`};
  width: ${({ fullWidth }) => fullWidth ? `100%` : `auto`};
`;
