import MixpanelInstance, { Mixpanel } from 'mixpanel-browser';
import React, {
  createContext,
  FunctionComponent,
  PropsWithChildren
} from 'react';

MixpanelInstance.init(process.env.REACT_APP_MIXPANEL_CLIENT_KEY!, {
  api_host: 'https://api.mixpanel.com'
});

export type MixpanelProviderValue = Mixpanel;

export const MixpanelContext = createContext<MixpanelProviderValue>(MixpanelInstance);

export const MixpanelProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  //MixpanelInstance.identify(/* User ID */);
  //MixpanelInstance.people.set_once({/* User Data */});

  return (
    <MixpanelContext.Provider value={MixpanelInstance}>
      {children}
    </MixpanelContext.Provider>
  );
}
