import { CheckPicker } from 'rsuite';
import styled from 'styled-components';

export const SelectStyled = styled(CheckPicker)`
  & {
    color: ${({ theme }) => theme.palette.gray.h60};
    font-size: 12px;
  }

  & > .rs-picker-toggle {
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.blue.h60}!important;
    }

    &:focus {
      border: 1px solid ${({ theme }) => theme.palette.blue.h40}!important;
    }
  
    &:focus-visible {
      outline: 3px solid ${({ theme }) => theme.palette.blue.h20}!important;
    }
  }
`;
