import { Input, InputProps } from 'rsuite';
import React, { FunctionComponent } from 'react';
import { Container } from './styles';

export interface ChatTextAreaProps extends Omit<InputProps, 'as'> {}

export const ChatTextArea: FunctionComponent<ChatTextAreaProps> = ({ ...props }) => {
  return (
    <Container>
      <Input 
        as="textarea" 
        rows={4}
        {...props} 
      />
    </Container>
  );
}
