import { Dict } from 'mixpanel-browser';
import { useCallback } from 'react';
import { MixpanelEvent } from 'src/core/flags';
import { useMixpanel } from './useMixpanel';

export type UseTrackType = (event: MixpanelEvent, properties?: Dict) => void;

export function useTrack(): UseTrackType {
  const mixpanel = useMixpanel();

  return useCallback((
    event: MixpanelEvent,
    properties?: Dict
  ) => {
    console.log(`[Mixpanel Event] ${event}`);
    mixpanel.track(event, properties);
  }, [mixpanel]);
}
