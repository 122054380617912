import { useContext } from 'react';
import { useBearer } from 'src/core/hooks/auth';
import { AxiosContext, AxiosProviderValue } from 'src/core/providers';

export function useAxios(): AxiosProviderValue {
  const [isTokenValid, token] = useBearer();
  const state = useContext(AxiosContext);

  if (!state) {
    throw new Error('useAxios must be used within an AxiosProvider');
  }

  if (!isTokenValid) {
    throw new Error('useAxios must be used after Bearer token is valid');
  }

  const [axios] = state;

  Object.assign(axios.defaults.headers ?? {}, {
    Authorization: `Bearer ${token}`
  });

  return state;
}
