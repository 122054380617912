import { useContext } from 'react';
import { MixpanelContext, MixpanelProviderValue } from 'src/core/providers';

export type UseMixpanelType = MixpanelProviderValue;

export function useMixpanel(): UseMixpanelType {
  const state = useContext(MixpanelContext);

  if (!state) {
    throw new Error('useMixpanel must be used within a MixpanelProvider');
  }

  return state;
}
