import { useEffectOnce } from 'usehooks-ts';
import { useCallback, useRef, useState } from 'react';

export type MenuChoiceType = number;
export type MenuDataType<T> = T | undefined;
export type MenuResetChoiceCallback = () => void;
export type MenuChoiceEventHandler<T> = (choiceId: MenuChoiceType, data: MenuDataType<T>) => void;
export type MenuEmitChoiceCallback<T> = (choiceId: MenuChoiceType, data: MenuDataType<T>) => void;
export type MenuGetChoiceIndexCallback = () => MenuChoiceType;

export type UseMenuType<T> = [
  MenuChoiceType, 
  MenuDataType<T>,
  MenuResetChoiceCallback, 
  MenuEmitChoiceCallback<T>, 
  MenuGetChoiceIndexCallback
];

export function useMenu<T>(
  onChoose?: MenuChoiceEventHandler<T>, 
  initialChoice: number = 0, 
  initialData: MenuDataType<T> = undefined
): UseMenuType<T> {
  const [choice, setChoice] = useState<MenuChoiceType>(initialChoice);
  const [data, setData] = useState<MenuDataType<T>>(initialData);
  const indexRef = useRef(0);

  useEffectOnce(() => {
    onChoose?.(initialChoice, initialData);
  });

  const handleResetChoice = useCallback(() => {
    setChoice(initialChoice);
  }, [initialChoice, setChoice]);

  const handleGetId = useCallback(() => {
    return indexRef.current++;
  }, []);

  const handleSetChoice = useCallback((choiceId: MenuChoiceType, data: MenuDataType<T>) => {
    setChoice(choiceId);
    setData(data);

    onChoose?.(choiceId, data);
  }, [onChoose]);

  return [
    choice, 
    data, 
    handleResetChoice, 
    handleSetChoice, 
    handleGetId
  ];
}
