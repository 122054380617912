import { Checkbox } from 'rsuite';
import styled from 'styled-components';

export interface CheckboxStyledProps {}

export const CheckboxStyled = styled(Checkbox)<CheckboxStyledProps>`
  & {
    color: ${({ theme }) => theme.palette.gray.h60};
    font-size: 14px;
  }

  &[class*="rs-checkbox-checked"] 
    .rs-checkbox-checker 
    .rs-checkbox-wrapper 
    .rs-checkbox-inner::before 
  {
    background-color: ${({ theme }) => theme.palette.blue.h80};
    border-color: ${({ theme }) => theme.palette.blue.h90};
  }
`;
